.App {
  background: #000000;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
a {
  color: inherit !important;
  text-decoration: none !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: linear-gradient(180deg, #000000 0%, #231c04 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fade_animation {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.nav-link {
  color: #fff !important;
  text-decoration: none;
  padding: 1rem !important;
  transition: color 0.3s ease !important;
}

.nav-link:hover {
  color: #07f2c7 !important;
  background-color: rgba(7, 242, 199, 0.2) !important;
}

.active {
  background-color: rgba(7, 242, 199, 0.2) !important;
  border-left: 8px solid #07f2c7 !important;
  padding: 1rem !important;
  color: #ffffff !important;
  display: flex !important;
  font-weight: 600 !important;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
