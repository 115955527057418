.heroBackground {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
}

.table {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  /* width: 100%; */
  table-layout: fixed;
}
.table tr {
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.table th,
.table td {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 6px;
  text-align: left;
}
.tableSub {
  padding-right: 2px !important;
  font-size: 11px !important;
}

.tableSubPortfolio {
  padding-right: 2px !important;
  font-size: 11px !important;
  color: #18b13f;

  background-color: rgba(24, 177, 63, 0.1);
}
.table th {
  font-size: 14px;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #fff;
}
.table td {
  font-size: 16px;
  padding: 10px 20px;
  color: #fff;
}

.table1 {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  /* width: 100%; */
  table-layout: fixed;
}
.table1 tr {
  border: 1px solid #ddd;
}
.table1 th,
.table1 td {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 6px;
}

.table1 th {
  font-size: 12px;
  padding: 10px 8px;
  text-transform: uppercase;
  color: #fff;
  /* text-align: left; */
  background-color: #14141c;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.table1 td {
  font-size: 14px;
  color: #fff;
  width: 8rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}
.greenColor {
  background-color: rgba(24, 177, 63, 0.3);
  text-align: left;
}
.redColor {
  background-color: rgba(237, 71, 49, 0.3);
  text-align: left;
}
.greenColor1 {
  background-color: rgba(24, 177, 63, 0.3);
  text-align: right;
}
.redColor1 {
  background-color: rgba(237, 71, 49, 0.3);
  text-align: right;
}
.ml10 {
  margin-left: 20px;
}

.table3 {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  /* width: 100%; */
  table-layout: fixed;
  border-bottom: none;
}

.table3 th {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 16px;
  padding: 10px 15px;
  color: #fff;
  border-bottom: none;
  background-color: #14141c;
}

/* // user table */
.tableUser {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  width: 90%;
  table-layout: fixed;
}
.tableUser th,
.tableUser td {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 6px;
  text-align: left;
}

.tableUser th {
  font-size: 14px;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #fff;
  border-bottom: none;
  background-color: #14141c;
}
.tableUser td {
  font-size: 16px;
  padding: 10px 20px;
  color: #fff;
  border-bottom: none;
  width: 100%;
}

.table_container {
  overflow-x: auto;
}
.tableUser1 {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  width: 100%;
}
.tableUser1 th,
.tableUser1 td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 6px;
}

.tableUser1 th {
  font-size: 14px;
  padding: 10px 6px;
  text-transform: uppercase;
  color: #fff;
  border-bottom: none;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}
.tableUser1 td {
  font-size: 14px;
  padding: 10px 6px;
  color: #fff;
  border-bottom: none;
  text-align: right;
  font-family: "Poppins", sans-serif;
}

.tableUser2 {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  width: 90%;
  table-layout: fixed;
}
.tableUser2 th,
.tableUser2 td {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 6px;
}

.tableUser2 th {
  font-size: 12px;
  padding: 10px 4px;
  text-transform: uppercase;
  color: #fff;
  border-bottom: none;
  text-align: right;
  font-family: "Montserrat", sans-serif;
}
.tableUser2 td {
  font-size: 14px;
  padding: 10px 8px;
  color: #fff;
  border-bottom: none;
  font-family: "Poppins", sans-serif;
}

.tableUser3 {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  width: 40.3%;
}
.tableUser3 th,
.tableUser3 td {
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 10px 6px;
}

.tableUser3 th {
  font-size: 14px;
  padding: 10px 20px;
  text-transform: uppercase;
  color: #fff;
  border-bottom: none;
  text-align: left;
  font-family: "Montserrat", sans-serif;
}
.tableUser3 td {
  font-size: 14px;
  padding: 10px 17px;
  color: #fff;
  border-bottom: none;
  text-align: right;
  font-family: "Poppins", sans-serif;
}
